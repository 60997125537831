// @ for input error text
.input_suggestion_error {
  color: $error;
  font-size: 0.85rem;
  font-weight: 405;
}
.primary_color {
  color: $primary-color;
}
.secondary_color {
  color: $secondary_color;
}
.count {
  font-weight: 800;
  color: $secondary_color;
}

/* ====================================
       * Toast notification
==================================== */
.Toastify__toast {
  box-shadow: 0 0px 1px 0 $primary-color !important;
}
.Toastify__toast-theme--light {
  color: black !important;
}

.Toastify__toast-container {
  top: auto;
  right: 1em !important;
  bottom: 1em !important;
  font-size: 14px !important;
}

.Toastify__progress-bar--success {
  background-color: $primary-color !important;
}
.Toastify__toast--success {
  svg {
    fill: $primary-color !important;
  }
}
.Toastify__close-button {
  svg {
    &:hover {
      fill: rgb(113, 20, 20) !important;
    }
  }
  &:hover {
    background-color: transparent !important;
  }
}
