.getQuote_section {
  .getQuote_box {
    box-shadow: 0px 0px 1px 0px $primary-color;
    transition: 0.4s ease;
    padding: 1rem;
    border-radius: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
      background-color: $hover_bg;
      cursor: pointer;
    }
    .icon {
      font-size: 34px;
      color: $secondary_color;
    }
    .title {
      font-size: 20px;
      font-weight: 700;
      color: $primary-color;
    }
    .button_section {
      &:hover {
        button {
          color: $primary-color;
          background-color: transparent !important;
        }
      }
    }
    @media screen and (max-width: 767px) {
      gap: 20px;
      flex-direction: column;
      .text {
        text-align: justify;
      }
      .button_section {
        width: 100%;
        padding-left: -20px;
      }
    }
  }
}
