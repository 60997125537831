.navbar_section,
.navbar_section2 {
  max-width: 100vw !important;
  overflow: hidden !important;
  background: $primary-color;
  .navbar {
    padding: 0 !important;
  }
  .navbar-brand {
    font-size: 1.1rem !important;
    font-weight: 700 !important;
    color: white !important;
    // display: non;
    &:hover {
      color: white !important;
    }
  }
  .nav-link {
    padding: 0.6rem 1.5rem !important;
    color: white !important;
    transition: 0.2s ease;
    &:hover {
      color: $secondary_color !important;
    }
  }
  // active nav_link
  .active.nav-link {
    background-color: $secondary_color;
    &:hover {
      color: white !important;
    }
  }
  button {
    transition: 0.4s ease !important;
  }
  // change navbar toggle button image
  .navbar-toggler-icon {
    margin-top: 0 !important;
    margin-bottom: 2.5px !important;
    background-image: url(../../images/icons/menuBar.svg) !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .btn-close {
    font-size: 11px !important;
  }
}

// * top nav
.top_navSection {
  padding: 0.5rem 0;
  .topNav_box {
    // background-color: rebeccapurple;
    display: flex;
    justify-content: space-between;

    gap: 20px;
    .logo {
      display: flex;
      gap: 10px;
      img {
        // width: 300px;
        height: 80px;
      }
      @media screen and (max-width: 767px) {
        display: flex;
        justify-content: center;
      }
    }
    .slogan {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $secondary_color;
      font-weight: 650;
      font-size: 1.2rem;
      span {
        font-size: 0.9rem;
      }
    }
    .contact_info {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 20px;
      .contact_box {
        display: flex;
        gap: 5px;
        svg {
          font-size: 20px;
          color: $secondary_color;
          @media screen and (max-width: 767px) {
            font-size: 18px;
          }
        }
        .info {
          .title {
            color: $secondary_color;
            font-weight: 600;
          }
          @media screen and (max-width: 767px) {
            display: flex;
            gap: 10px;
            flex-direction: row;
          }
        }
      }
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    @media screen and (max-width: 991px) {
      flex-direction: column;
      gap: 15px;
    }
  }
}

// * make navbar sticky
.navbar_section2 {
  width: 100%;
  z-index: 99;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
}
