.scroll {
  z-index: 9999;
  position: fixed;
  left: 10px;
  bottom: 10px;
  font-size: 30px;
  svg {
    background-color: white;
    border-radius: 0.5rem;
    border: 1px solid white;
  }

  color: $primary-color;
  transition: 0.4s;
  &:hover {
    color: $secondary_color;
    cursor: pointer;
  }
}
