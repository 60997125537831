.contact_section {
  // * contact form
  .contactForm_section {
    .contactForm_top {
      svg {
        color: $secondary_color;
        font-size: 28px;
      }
    }
    a {
      color: $secondary_color;
    }
  }
  // * side contain
  .side_section {
    .title_text {
      color: $secondary_color;
      font-weight: 800;
    }
    .item {
      padding-bottom: 1.8rem;
      .count {
        color: $secondary_color;
        font-weight: 700;
      }
      display: flex;
      gap: 8px;
      .text {
        font-weight: 700;
      }
      .info {
        font-size: 15px;
      }
    }
  }

  // * contact info
  .contactInfo_section {
    .cardBox_section {
      transition: 0.4s ease;
      box-shadow: 0px 0px 2px 0px $primary-color;
      height: 100%;
      &:hover {
        background-color: $hover_bg;
      }
    }
    .info_box {
      display: flex;
      gap: 10px;
      .icon {
        svg {
          margin-top: 0.25rem;
          font-size: 28px;
          color: $primary-color;
          opacity: 0.85;
        }
      }
      .info {
        a {
          color: black;
        }
        .title {
          font-size: 18px;
          color: $primary-color;
          font-weight: 600;
        }
        .details {
          display: flex;
          align-items: center;
          gap: 5px;
        }
        .contact_info_details {
          display: flex;
          gap: 20px;
          flex-wrap: wrap;
          a {
            color: black;
          }
          @media screen and (max-width: 767px) {
            gap: 0;
          }
        }
      }
    }
  }

  .center {
    display: flex;
    justify-content: center;
  }
}
