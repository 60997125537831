/* animation for zooming */
@keyframes popPageNotFound_zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
/* animation for  left moving */
@keyframes paddingLeft {
  0% {
    margin-left: 0rem;
  }

  50% {
    margin-left: 1rem;
  }

  100% {
    margin-left: 0rem;
  }
}
/* style */
.notFound_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;

  .notFound_title {
    font-size: 10rem !important;
    font-weight: 700;
    /* color for 404 big text */
    color: $primary-color;
    animation-name: popPageNotFound_zoom;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    padding: 0 0.2rem !important;
  }
  .pageNotFound_text {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.5rem;
  }
  .pageNotFound_description {
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    padding: 1rem 0;
  }
}
