.footer_section {
  background-color: $primary-color;
  padding: 2.2rem 0;
  border-bottom: 1px solid gray;
  color: white;
  margin-top: auto;
  height: 100%;
  width: 100%;
  a {
    color: white;
    &:hover {
      color: $secondary_color;
    }
  }
  .logo {
    img {
      width: 100px;
      margin-bottom: 0.3rem;
    }
  }
  .footer_items {
    .footer_item {
      padding: 0.25rem 0;
      transition: 0.4s ease;

      &:hover {
        padding-left: 0.5rem;
        cursor: pointer;
      }
    }
  }
  .footer_contact_us {
    .footer_item {
      display: flex;
      gap: 5px;
      align-items: center;
      padding: 0.4rem 0;
      svg {
        margin-bottom: 0.25rem;
      }
    }
  }
  .footer_social_icon {
    .social_icon {
      display: flex;
      justify-content: center;
      gap: 25px;
      align-items: center;
      .title {
        font-weight: 600;
      }
      svg {
        transition: 0.4s ease;
        font-size: 18px;
        &:hover {
          transform: scale(1.1);
          color: $secondary_color;
          cursor: pointer;
        }
      }
    }
  }
}
// * copyright
.copyright_section {
  color: white;
  a {
    color: white;
  }
  padding: 0.8rem 0;
  background-color: $primary-color;
  .copyright_box {
    display: flex;
    justify-content: space-between;
    a {
      &:hover {
        color: $secondary_color;
      }
    }
    @media screen and (max-width: 991px) {
      align-items: center;
      gap: 10px;
      flex-direction: column;
    }
  }
}

// footer-title
.footerTitle {
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 0.7rem;
  .progress_bar {
    // width: 50px;
    position: relative;
  }
  .color {
    position: absolute;
    background-color: $secondary_color;
    opacity: 0.6;
    // width: 0px;
    width: 75px;
    height: 1px;
    // animation: progress 0.6s infinite alternate;
  }
  @keyframes progress {
    0% {
      width: 50%;
    }
    25% {
      width: 50%;
    }
    50% {
      width: 75%;
    }
    75% {
      width: 85%;
    }
    100% {
      width: 100%;
    }
  }
}
