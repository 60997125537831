.program_section {
  .container {
    .title {
      font-size: 24px;
      font-weight: 700;
      color: $primary-color;
      padding-bottom: 0.5rem;
    }
    .program_list {
      padding-top: 1rem;
      .title {
        font-size: 19px;
        color: $primary-color;
      }
      .item {
        display: flex;
        gap: 5px;
        padding: 0.25rem 0;
        transition: 0.4s ease;
        &:hover {
          cursor: pointer;
          padding-left: 0.5rem;
        }
      }
    }
  }
}
