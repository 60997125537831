// @ custom styling for button
.button_section {
  button {
    width: 100%;
    font-size: 15px;
    padding: 0.25rem 2rem 0.3rem 2rem;
    border-radius: 1rem;
    transition: 0.4s ease;
    height: 36px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: center;
  }
  // @ for primary button
  .primary {
    background-color: $primary_color;
    outline: 1px solid $primary_color;
    color: white;
  }
  // @ for primary outline button
  .primary_outline {
    background-color: transparent;
    outline: 1px solid $primary_color;
    color: $primary_color;
  }
  &:hover {
    button {
      background-color: $hover_color;
    }
  }
}
