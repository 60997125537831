* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif !important;

  // * for make non draggable
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  font-weight: 500;
  scroll-behavior: smooth;
}
body {
  background-color: $bg_color !important;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}
ul {
  padding: 0 !important;
  margin: 0 !important;
}

li {
  list-style: none;
}
a {
  text-decoration: none !important;
}
button {
  border: none !important;
  &:focus {
    box-shadow: none !important;
  }
}

// * for scroll bar

::selection {
  color: $primary-color;
  background: white;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $primary-color;
}
// input[type="checkbox"] {
//  }
