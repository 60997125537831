.aboutPage_section {
  // * about me
  .aboutMe_section {
    .title {
      font-size: 28px;
      font-weight: 700;
      .secondary_color {
        font-weight: 700;
        // text-decoration: underline;
      }
    }
    .heading {
      padding-top: 1rem;
      color: $primary-color;
      font-weight: 700;
    }
    .image_layer {
      img {
        width: 100%;
        object-fit: cover;
      }
      position: relative;
      .overlap {
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 20px;
        font-weight: 700;
        color: white;
        background-color: $primary-color;
        padding: 1.25rem 2rem;
        .h1 {
          //   color: $secondary_color;
          font-weight: bolder;
        }
      }
    }
  }
  //   * WhyChooseUs
  .whyChooseUs_section {
    .title {
      text-align: center;
      font-size: 28px;
      font-weight: 700;
      .secondary_color {
        font-weight: 700;
      }

      @media screen and (max-width: 767px) {
        text-align: left;
      }
    }
    .text {
      text-align: center;
      @media screen and (max-width: 767px) {
        text-align: left;
      }
    }
    .contain_box {
      box-shadow: 0px 0px 1px 0px $primary-color;
      border-radius: 0.2rem;
      height: 100%;
      transition: 0.4s ease;
      .image {
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      .title {
        padding: 1rem;
        font-size: 16px;
        font-weight: 600;
        color: $primary-color;
        text-align: left;
      }
      &:hover {
        background-color: $hover_bg;
        cursor: pointer;
      }
    }
  }
  .goal_section {
    .title {
      text-align: center;
    }
  }
}
// * goal
.goal_section {
  .title {
    font-size: 28px;
    font-weight: 700;
    .secondary_color {
      font-weight: 700;
    }
  }
  .contain_box {
    .item_box {
      // display: flex;
      align-items: flex-end;
      gap: 5px;
      .count {
        color: $secondary_color;
        font-weight: 600;
        font-size: 32px;
      }
      .title {
        margin-bottom: 0.25rem;
        font-size: 20px;
      }
    }
  }
}
