// @ custom input styling
.input_section {
  outline: 1px solid $primary_color;
  display: flex;
  padding: 0.4rem 0.6rem 0.4rem;
  gap: 10px;
  border-radius: 0.2rem;
  box-shadow: 0px 0px 3px 0px $primary_color;
  textarea {
    width: 100%;
    outline: none !important;
    border: none !important;
    background-color: transparent;
  }
  input {
    border-radius: 0 !important;
    padding: 0 !important;
    transition: 0.2 ease;
    outline: none !important;
    border: none !important;
    background-color: transparent;
    font-size: 14px;
    width: 100%;
    &:focus {
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
      background: transparent;
    }
    &::placeholder {
      font-size: 14px;
    }
  }
  .icon {
    display: flex;
    align-items: center;
    svg {
      color: $primary_color;
      font-size: 18px;
    }
  }
  &:hover {
    box-shadow: 0px 0px 3px 0px $primary_color;
  }
}
.input_section.error {
  outline: 1px solid $error;
  box-shadow: 0px 0px 3px 0px $error;
  svg {
    color: red;
  }
}
