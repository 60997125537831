.title_section {
  .title_text {
    font-size: 18px;
    font-weight: 600;
  }
  .subTitle_text {
    font-size: 16px;
    font-weight: 550;
  }
}
