.teamMember_section {
  .title {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    .secondary_color {
      font-weight: 700;
    }
  }
  .heading {
    font-size: 1.2rem;
    font-weight: 600;
    color: $secondary_color;
  }

  .card_box {
    display: flex;
    align-items: center;
    gap: 15px;
    img {
      width: 160px;
      height: 160px;
      object-fit: cover;
      border-radius: 0.2rem;
      // background-color: red;
    }
    .details {
      .name {
        font-size: 1.4rem;
        font-weight: 600;
      }
      .position {
        font-size: 1rem;
        font-weight: 600;
        padding: 0.2rem 0;
      }
      .email {
        a {
          display: flex;
          align-items: center;
          gap: 5px;
          color: black;
          transition: 0.4s ease;
          &:hover {
            color: $secondary_color;
          }
        }
      }
      @media screen and (max-width: 767px) {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        .position {
          text-align: center;
        }
        a {
          text-align: center;
        }
      }
    }
    @media screen and (max-width: 767px) {
      gap: 0px;
      flex-direction: column;
    }
  }
  .founder_team {
    .card_box {
      &:nth-child(1) {
        flex-direction: row-reverse;
      }
      @media screen and (max-width: 767px) {
        &:nth-child(1) {
          flex-direction: column;
        }
      }
    }
  }
  .small_team {
    .card_box {
      flex-direction: column;
      gap: 0px;
      .details {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    }
  }
  .small_team.center {
    display: flex;
    justify-content: center;
  }
}
