.servicePage_section {
  .serviceBox_section {
    .service_box {
      border-radius: 0.2rem;
      height: 100%;
      box-shadow: 0px 0px 1px 0px $primary-color;
      transition: 0.4s ease;
      img {
        width: 100%;
        object-fit: contain;
      }
      .title {
        font-size: 19px;
        font-weight: 700;
        padding: 0.5rem 0;
        color: $primary-color;
        padding: 1rem 1rem 0.5rem 1rem;
      }
      .subtitle {
        padding: 0.5rem 1rem 1rem 1rem;
        .item {
          padding: 0.2rem 0;
          display: flex;
          align-items: flex-start;
          gap: 5px;
          svg {
            font-size: 13px;
            margin-bottom: 0.2rem;
          }
        }
      }
      &:hover {
        background-color: $hover_bg;
        cursor: pointer;
      }
    }
  }
}
