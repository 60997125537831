.breadCrumb_section {
  .image_overlay {
    position: relative;
    width: 100%;
    img {
      width: 100%;
      height: 30vh;
      object-fit: cover;
    }
    .dark_overlay {
      top: 0;
      height: 100%;
      position: absolute;
      width: 100%;
      background-color: rgb(0, 0, 0, 0.5);
      z-index: 2;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      .title {
        font-size: 28px;
        font-weight: 700;
      }
    }
  }
}
