.homePage_section {
  // * feature section
  .feature_section {
    .cardBox_section {
      box-shadow: 0px 0px 1px 0px $primary-color;
      transition: 0.4s ease;
      .icon {
        text-align: center;
        font-size: 40px;
        color: $secondary_color;
        img {
          width: 60px;
          height: 60px;
        }
      }
      .title {
        padding-top: 0.5rem;
        text-align: center;
        color: $primary-color;
        font-size: 17px;
        font-weight: 700;
      }
      &:hover {
        background-color: $hover_bg;
        cursor: pointer;
      }
    }
    .info_section {
      height: 100%;
      flex-direction: column;
      justify-content: center;
      .title {
        font-size: 28px;
        font-weight: 700;
        text-align: center;
        .primary_color,
        .secondary_color {
          font-weight: 700;
        }
      }
      .text {
        text-align: center;
        padding: 1rem 0 2.5rem 0;
      }
      @media screen and (max-width: 767px) {
        .title,
        .text {
          text-align: left;
          text-align: justify;
        }
      }
      .button_section {
        button {
          width: 190px;
        }
      }
    }
    .container_box {
      display: flex;
      align-items: center;
    }
  }
  // * home carousel
  .homeCarousel_section {
    // position: absolute;
    width: 100%;
    img {
      height: calc(100vh - 135px);
      object-fit: cover;
      @media screen and (max-width: 767px) {
        height: calc(100vh - 235px);
      }
    }
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      background-image: none !important;
    }
  }
  // * feature2_section
  .feature2_section {
    .cardBox_section {
      box-shadow: 0px 0px 1px 0px $primary-color;
      transition: 0.4s ease;
      height: 100%;

      img {
        width: 100%;
        margin-bottom: 1rem;
      }

      &:hover {
        background-color: $hover_bg;
        cursor: pointer;
      }
    }
    .title {
      font-size: 28px;
      font-weight: 700;
      .secondary_color {
        font-weight: 700;
      }
    }
    .title_box {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: 767px) {
        align-items: flex-start;
      }
    }
    .contain_box {
      .title {
        font-size: 20px;
        padding-bottom: 0.5rem;
        font-weight: 900;
      }
      .text {
        font-size: 15px;
        text-align: justify;
      }
    }
  }
  // * feature3_section
  .feature3_section {
    .title {
      font-weight: 700;
      .secondary_color {
        font-size: 28px;
        font-weight: 900 !important;
      }
    }
    .contain_box {
      display: flex;
      align-items: center;
      .secondary_box {
        // background-color: $secondary_color;
        box-shadow: 0px 0px 1px 0px $primary-color;
        transition: 0.4s ease;
        // height: 40vh;
        height: 100%;
        border-radius: 0.2rem;
        padding: 1rem;
      }
      .card_box {
        padding: 1rem;
        height: 100%;
        box-shadow: -1px 0px 1px 0px $primary-color;
        transition: 0.4s ease;
        border-top-left-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem;
        @media screen and (max-width: 767px) {
          box-shadow: 0px 0px 1px 0px $primary-color;
        }
      }
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
}
