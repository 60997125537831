.model_section {
  // // @include model;
  // // overflow-y: auto;

  // display: block;
  // height: 100%;
  // width: 100%;

  // background-color: rgba($color: #000, $alpha: 0.7);
  // position: absolute;
  // top: 0;
  // left: 0;
  // z-index: 99;

  // .close {
  //   svg {
  //     background-color: white !important;
  //     position: fixed;
  //     right: 20px;
  //     top: 20px;
  //     font-size: 2.2rem;
  //     color: black;
  //     &:hover {
  //       cursor: pointer;
  //     }
  //   }
  // }

  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000, $alpha: 0.7);

  .header {
    display: flex;
    justify-content: flex-end;
    svg {
      background-color: white;
      font-size: 1.8rem;
      color: $secondary_color;
      padding: 0.1rem;
      &:hover {
        cursor: pointer;
      }
    }
    padding-bottom: 1rem;
  }
  .contain {
    display: flex;
    justify-content: center;
    img {
      // width: 100%;
      object-fit: contain;

      @media screen and (max-width: 768px) {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
.model_section.hide {
  display: none;
}
